import axios from "../core/axios";
import { assertSuccess, paramsParser } from "../core";
import { Headers } from "./generic";
import type { CollectionResult, MessageResult, Result } from "../types";
import type { BlockEntity, ListBlockParams } from "../types/block";

export class Block {
  static async createBlock(targetUserId: string, auth: string) {
    const resp = await axios.post<Result<BlockEntity>>(
      `/block/${targetUserId}`,
      undefined,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async removeBlock(targetUserId: string, auth: string) {
    const resp = await axios.delete<MessageResult<"User unblocked">>(
      `/block/${targetUserId}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async listBlocks(params: ListBlockParams, auth: string) {
    const resp = await axios.get<CollectionResult<BlockEntity>>(
      `/block${paramsParser(params)}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    const { data, meta } = assertSuccess(resp.data);

    return {
      data,
      meta,
    };
  }
}
